<template>
  <b-overlay :show="isLoggingIn" rounded="sm">
    <b-row
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <b-col class="col-xl-3 col-lg-6 col-md-7 col-sm-12">
        <b-card>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ T("Web.Generic.RegistrationPage.WelcomeTo", "Welcome to") }}
            <span class="text-primary font-weight-bold">Affald.online</span>
          </b-card-title>
          <b-card-text class="mb-2">
            {{
              T(
                "Web.Generic.RegistrationPage.CreateYourPassword",
                "Create your password to complete the registration"
              )
            }}</b-card-text
          >
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{
                  T("Web.Generic.Password")
                }}</label>
              </div>
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-group>
                <div class="d-flex justify-content-between mt-1">
                  <label for="login-Confirmpassword">{{
                    T(
                      "Web.Generic.RegistrationPage.ConfirmPassword",
                      "Confirm password"
                    )
                  }}</label>
                </div>
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-confirmpassword"
                    v-model="confirmpassword"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-confirmpassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form-group>
            <div class="d-flex">
              <span
                class="font-weight-bold"
                :class="{
                  'text-primary': passwordMatch,
                  'text-danger': !passwordMatch,
                }"
                >{{
                  T(
                    "Web.Generic.RegistrationPage.PasswordMatchText",
                    "Passwords need to match"
                  )
                }}
                <feather-icon :icon="passwordMatch ? 'CheckIcon' : 'XIcon'"
              /></span>
            </div>
            <div class="d-flex mb-2">
              <span
                class="font-weight-bold"
                :class="{
                  'text-primary': passwordLengthValid,
                  'text-danger': !passwordLengthValid,
                }"
                >{{
                  T(
                    "Web.Generic.RegistrationPage.PasswordNeedsToBe9CharText",
                    "Password must be at least 9 characters long"
                  )
                }}
                <feather-icon
                  :icon="passwordLengthValid ? 'CheckIcon' : 'XIcon'"
              /></span>
            </div>
            <b-button
              type="submit"
              variant="primary"
              block
              @click="handleSubmit"
              :disabled="loggingIn || !passwordIsValid"
            >
              {{ T("Web.Generic.RegistrationPage.CompleteRegistration", "Complete registration") }}
            </b-button>
            <span
              class="font-weight-bold mt-1 d-block"
              :class="{
                'text-primary': newResetMailSent,
                'text-danger': !newResetMailSent,
              }"
              v-if="errorMsg != null && errorMsg == 'Invalid token.'"
            >
              <span v-if="newResetMailSent">{{
                $t("Register.ResetMailSent")
              }}</span>
              <span v-else>
                <span>{{ $t("Register.LinkExpired") }} </span>
                <a @click.prevent="sendNewResetMail" href="#"
                  >{{ $t("Register.ClickToSendNewLink") }}
                </a>
              </span>
            </span>
            <span v-else-if="errorMsg != null" class="text-danger">{{
              errorMsg
            }}</span>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  BCard,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
  },
  mixins: [togglePasswordVisibility],
  created() {
    this.token = this.$route.query.token;
    this.email = this.$route.params.email;
  },
  data() {
    return {
      email: null,
      token: "",
      password: "",
      confirmpassword: "",
      required,
      loggingIn: false,
      errorMsg: null,
      errors: [],
      newResetMailSent: false,
    };
  },
  computed: {
    ...mapGetters({ isLoggingIn: "auth/isLogginIn" }),
    passwordLengthValid() {
      return this.password.length >= 9;
    },
    passwordMatch() {
      return this.password != "" && this.password == this.confirmpassword;
    },
    passwordIsValid() {
      return this.passwordLengthValid && this.passwordMatch;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      tryLogin: "appUser/loginAsync",
      putPassword: "accountUsers/setPassword",
      forgotPassword: "accountUsers/forgotPassword",
    }),
    async handleSubmit() {
      if (!this.passwordIsValid) return;
      try {
        this.loggingIn = true;
        this.errorMsg = null;
        await this.putPassword({
          Email: this.email,
          Token: this.token,
          Password: this.password,
        });
        await this.tryLogin({ email: this.email, password: this.password });
      } catch (error) {
        this.errorMsg = error.data.message;
      } finally {
        this.loggingIn = false;
      }
    },
    async sendNewResetMail() {
      await this.sendForgotPassword();
      this.newResetMailSent = true;
    },
    async sendForgotPassword() {
      await this.forgotPassword({ email: this.email });
    },
  },
};
</script>

<style lang="scss">
</style>
